.header {
  padding: 20px 20px 30px;
  height: 190px;
  position: relative;
  overflow: hidden;
}

.header .title {
  font-family: quicksand;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  color: white;
  font-size: 30px;
}

@media (min-width: 500px) {
  .header .title {
    font-size: 40px;
  }
}

.bold {
  font-weight: bold;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav a, .logout {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.bigShopIcon {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
  width: 70px;
}

@media (min-width: 500px) {
  .bigShopIcon {
    width: 120px;
  }
}

a.activeLink {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}
