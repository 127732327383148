.userMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 150px;
  pointer-events: none;
}

.userBackground {
  background: white;
  position: absolute;
  width: 400px;
  height: 300px;
  left: 100%;
  bottom: 100%;
  border-radius: 100%;
  transition: transform 0.3s ease-in-out;
}

.userIcon {
  fill: white;
  width: 30px;
  height: 30px;
}

.userMenu.open .userBackground {
  transform: translate(-190px, 190px);
}

.userMenu.open .userIcon {
  fill: var(--purple);
}

.userMenuTrigger {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  pointer-events: all;
}

.userMenuContainer {
  position: absolute;
  top: 70px;
  right: 30px;
  display: flex;
  flex-direction: column;
}

.link {
  text-align: center;
  line-height: 1.75;
  text-decoration: none;
  color: var(--purple);
  pointer-events: all;
}

.link:hover {
  text-decoration: underline;
}

.logout {
  border: none;
  background: none;
  font-size: inherit;
  padding: 0;
}
