.button {
  border: 0;
  padding: 6px 12px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  border-radius: 3px;
  align-items: center;
  color: white;
}

.button.blue {
  background: var(--blue);
}

.button.green {
  background: var(--darker-green);
}

.button.red {
  background: var(--red);
}

.button.pink {
  background: var(--pink);
}

.button.outline.blue {
  border: 1px solid var(--blue);
  color: var(--blue);
  background-color: transparent;
}
.button.outline.blue path {
  fill: var(--blue);
}

.button.outline.red {
  border: 1px solid var(--red);
  color: var(--red);
  background-color: transparent;
}
.button.outline.red:hover {
  color: white;
  background-color: var(--red);
}

.button.outline.red path {
  fill: var(--red);
}

.button.outline.red:hover path {
  fill: white;
}

.button.outline.pink {
  border: 1px solid var(--pink);
  color: var(--pink);
  background-color: transparent;
}

.svg {
  width: 16px;
  margin-right: 6px;
}

.svg path {
  fill: white;
}
